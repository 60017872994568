import React from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import { useField, useFormikContext } from 'formik';

import FItem, { FItemProps } from './FItem';

type FItemInputNumberProps = InputNumberProps & {
  name: string;
  label?: string;
  formItemProps?: FItemProps;
  children?: React.ReactNode;
  StyledFItemWrapper?: React.ComponentType<FItemProps>;
};

const FItemInputNumber = ({
  name,
  label,
  formItemProps,
  children,
  StyledFItemWrapper: StyledFItem,
  ...props
}: FItemInputNumberProps) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);

  const FormItemWrapperComponent = StyledFItem || FItem;

  return (
    <FormItemWrapperComponent meta={meta} label={label} htmlFor={name} {...formItemProps}>
      <InputNumber
        id={name}
        {...field}
        onChange={val => formik.setFieldValue(name, val)}
        onBlur={() => formik.setFieldTouched(name)}
        {...props}
      />
      {children}
    </FormItemWrapperComponent>
  );
};

export default FItemInputNumber;
