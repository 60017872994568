import React from 'react';
import { Col, Row } from 'antd';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';
import PropTypes from 'prop-types';

export interface DetailRowProps {
  label: string;
  labelAlign?: 'left' | 'right';
  data: any;
  render: any;
  hasContentChanged?: boolean;
}

const DetailRow = (props: DetailRowProps) => {
  const { label, labelAlign = 'right', data, render } = props;

  if (_isNil(data) || (_isObject(data) && _isEmpty(data))) {
    return null;
  }

  let labelStyle = {};
  if (props.hasContentChanged) {
    labelStyle = {
      backgroundColor: '#FF7F00',
      marginLeft: 'auto',
      padding: '10px'
    };
  }
  const testLabel = label.replace(/\s/g, '_').replace(':', '').toLowerCase();

  return (
    <Row
      data-testid={testLabel}
      align="top"
      style={{ marginTop: '1.5rem', marginBottom: '1.5rem', alignItems: 'center' }}
      gutter={24}
    >
      <Col xs={24} md={8} style={{ color: '#000000', fontWeight: 500, textAlign: labelAlign }}>
        <div style={labelStyle}>{label}&#58;</div>
      </Col>
      <Col
        xs={24}
        md={16}
        style={{ display: 'flex', alignItems: 'center', wordBreak: 'break-all' }}
      >
        {render ? render(data) : data}
      </Col>
    </Row>
  );
};

DetailRow.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.any,
  render: PropTypes.func
};

export default DetailRow;
